import React, { useEffect, useRef } from "react";
import classes from "./Intro.module.scss";
import history from "utility/history";
import { TimelineMax, Power2, Expo } from "gsap";

import { connect } from "react-redux";
import * as actions from "store/actions";
const Intro = ({
  changeIntroState,
  artworks,
  featuredArtworks,
  getArtworks,
  getFeatured,
}) => {
  let contentRef = useRef(null);
  let moreRef = useRef(null);
  let splashRef = useRef(null);
  let tl = new TimelineMax();

  useEffect(() => {
    if (history.location.pathname.split("/")[1] === "admin")
      changeIntroState(false);
    else {
      if (!artworks) getArtworks();
      if (!featuredArtworks) getFeatured();
    }
    initAnimate();
  }, []);

  const initAnimate = () => {
    tl.fromTo(
      contentRef,
      1.5,
      {
        y: 100,
        opacity: 0,
        ease: Power2.easeInOut,
      },
      { y: 0, opacity: 1, ease: Power2.easeInOut }
    );
    tl.to(moreRef, 1, {
      opacity: 1,
      ease: Power2.easeInOut,
      delay: -1,
    });
  };

  const moreHandler = () => {
    tl.to(contentRef, 1, { ease: Expo.easeInOut, y: -100, opacity: 0 });
    tl.to(splashRef, 1, {
      ease: Power2.easeInOut,
      height: "100%",
      delay: -1,
    });
    tl.to(splashRef, 1, {
      ease: Power2.easeInOut,
      y: "-100%",
      delay: 0.5,
    });
    setTimeout(() => {
      changeIntroState(false);
    }, 2500);
  };

  return (
    <div className={classes.intro}>
      <div className={classes.container}>
        <div className={classes.content} ref={(el) => (contentRef = el)}>
          <h1>PERSONAL PORTFOLIO</h1>
          <h4>Rifqi Anshari</h4>
          <a
            className={classes.more}
            ref={(el) => (moreRef = el)}
            onClick={moreHandler}
          >
            Explore
          </a>
        </div>
        <div className={classes.splash} ref={(el) => (splashRef = el)} />
      </div>
    </div>
  );
};

const mapStateToStore = (state) => {
  return {
    artworks: state.project.artworks,
    featuredArtworks: state.project.featuredArtworks,
  };
};

const mapDispatchToStore = (dispatch) => {
  return {
    getFeatured: () => dispatch(actions.getFeatured()),
    getArtworks: () => dispatch(actions.getArtworks()),
  };
};

export default connect(mapStateToStore, mapDispatchToStore)(Intro);
