import * as actionTypes from "../actions/actionTypes";

const initialState = {
  introState: true,
};

const changeIntroState = (state, action) => {
  return {
    ...state,
    introState: action.state,
  };
};

const baseReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_INTRO_STATE:
      return changeIntroState(state, action);
    default:
      return state;
  }
};

export default baseReducer;
