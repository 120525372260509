import axios from "axios";
import queryString from "query-string";
export const baseURL = process.env.REACT_APP_BASE_URL_API;
export const baseURLWeb = process.env.REACT_APP_BASE_URL;

export const http = async params => {
  try {
    let auth = JSON.parse(localStorage.getItem("rif-web"));
    let query = params.query
      ? "?" + queryString.stringify(params.query, { arrayFormat: "bracket" })
      : "";
    let config = {
      method: params.method ? params.method : "GET",
      baseURL: baseURL,
      url: params.path ? params.path + (query || "") : "",
      data: params.data ? params.data : {},
      headers: {
        Authorization: "Bearer " + (auth ? auth.jwt : ""),
        "Content-Type": params.content_type
          ? params.content_type
          : "application/json"
      }

      // onUploadProgress: function(progressEvent) {
      //   percentCompleted = Math.round(
      //     (progressEvent.loaded * 100) / progressEvent.total
      //   );
      //   if (progress) {
      //     setProgress(percentCompleted);
      //   }
      // }
    };
    let { data } = await axios(config);
    return { success: true, response: data };
  } catch (err) {
    let newErr = { success: false, response: err };
    console.log(newErr);
    if (newErr.response.response) {
      if (newErr.response.response.data.message === "Token tidak valid!") {
        localStorage.removeItem("rif-web");
      }
    }
    return newErr;
  }
};
