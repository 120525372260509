import React, { forwardRef } from "react";
import classes from "./MyInfo.module.scss";

const MyInfo = forwardRef((props, ref) => {
  return (
    <div className={classes.myInfo} ref={ref}>
      <h1>
        Rifqi <span />
        Anshari
      </h1>
      <p>Frontend Developer</p>
    </div>
  );
});

export default MyInfo;
