import React, { useState } from "react";
import Loader from "../Loader/Loader";
import classes from "./BlurImage.module.scss";
import classNames from "classnames";
const BlurImage = ({
  src,
  smallSrc,
  classCustom,
  altImg,
  withLoader,
  onClick
}) => {
  const [imgSrc, setImgSrc] = useState(smallSrc);
  const [loaded, setLoaded] = useState(false);

  let img = new Image();
  img.onload = () => {
    setImgSrc(src);
    setLoaded(true);
  };
  img.src = src;

  return (
    <>
      {withLoader ? (
        <>
          {loaded ? (
            <img
              src={imgSrc}
              alt={altImg}
              className={classNames(classCustom, classes.blurImage, {
                [classes.loading]: !loaded
              })}
              onClick={onClick}
            />
          ) : (
            <Loader />
          )}
        </>
      ) : (
        <img
          src={imgSrc}
          alt={altImg}
          className={classNames(classCustom, classes.blurImage, {
            [classes.loading]: !loaded
          })}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default BlurImage;
