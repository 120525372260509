import React, { Component } from "react";
import { TweenMax, Expo, Power2 } from "gsap";
import classes from "./Artworks.module.scss";
import classNames from "classnames";
import { connect } from "react-redux";
import NavSlide from "components/Nav/Nav";
import BlurImage from "elements/UI/BlurImage";
import history from "utility/history";
import { baseURL } from "utility/http";
import { iconArrowCustom as ArrowIcon } from "commons/images";
import * as actions from "store/actions/index";

class Artworks extends Component {
  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
    this.splash = React.createRef();
    this.splashVertical = React.createRef();
    this.canva1Ref = React.createRef();
    this.canva2Ref = React.createRef();
  }
  state = {
    idSelected: "",
    selectedArtwork: "",
    loaded: false,
  };

  goTo = (id) => {
    if (id === this.state.idSelected) {
      return;
    }
    this.changeAnimate(id);
    setTimeout(() => {
      this.setState(
        {
          loaded: false,
        },
        () => {
          history.push(`/artworks/${id}`);
          this.getSelected(id);
        }
      );
    }, 500);
  };

  getSelected = async (idArtwork) => {
    const artwork = await this.filterArtwork(idArtwork);
    if (!artwork || artwork?.length < 1) {
      history.push("/");
    }
    this.setState(
      {
        idSelected: idArtwork,
        selectedArtwork: artwork,
      },
      () =>
        setTimeout(() => {
          this.setState({ loaded: true });
          this.initAnimate();
        }, 1000)
    );
  };

  filterArtwork = (id) => {
    return this.props.artworks.find((artwork) => {
      return artwork.id === id;
    });
  };

  changeAnimate = (id) => {
    const downAnimate = () => {
      TweenMax.fromTo(
        this.splash.current,
        0.8,
        { ease: Power2.easeInOut, height: "0%", opacity: 1 },
        { ease: Power2.easeInOut, height: "100%", opacity: 1 }
      );

      TweenMax.fromTo(
        this.splash.current,
        0.8,
        { ease: Power2.easeInOut, y: "0%", delay: 1.2 },
        { ease: Power2.easeInOut, y: "100%", opacity: 1, delay: 1.2 }
      );
    };

    const upAnimate = () => {
      TweenMax.fromTo(
        this.splash.current,
        0.8,
        { ease: Power2.easeInOut, height: "100%", y: "100%", opacity: 1 },
        { ease: Power2.easeInOut, y: "0%" }
      );

      TweenMax.fromTo(
        this.splash.current,
        0.8,
        { ease: Power2.easeInOut, y: "0%", delay: 1.2 },
        { ease: Power2.easeInOut, y: "-100%", opacity: 1, delay: 1.2 }
      );
    };

    const leftAnimate = () => {
      TweenMax.fromTo(
        this.splashVertical.current,
        0.8,
        {
          ease: Power2.easeInOut,
          width: "100%",
          x: "100%",
          opacity: 1,
        },
        {
          ease: Power2.easeInOut,
          x: "0%",
        }
      );

      TweenMax.fromTo(
        this.splashVertical.current,
        0.8,
        { ease: Power2.easeInOut, x: "0%", delay: 1.2 },
        { ease: Power2.easeInOut, x: "-100%", opacity: 1, delay: 1.2 }
      );
    };

    const rightAnimate = () => {
      TweenMax.fromTo(
        this.splashVertical.current,
        0.8,
        {
          ease: Power2.easeInOut,
          width: "0%",
          opacity: 1,
        },
        {
          ease: Power2.easeInOut,
          width: "100%",
        }
      );

      TweenMax.fromTo(
        this.splashVertical.current,
        0.8,
        { ease: Power2.easeInOut, x: "0%", delay: 1.2 },
        { ease: Power2.easeInOut, x: "100%", opacity: 1, delay: 1.2 }
      );
    };

    const { idSelected } = this.state;
    const { artworks } = this.props;
    const largeScreen = window.matchMedia("(min-width: 768px)");
    let indexPrev = artworks.findIndex((item) => item.id === idSelected);
    let indexNext = artworks.findIndex((item) => item.id === id);
    if (indexPrev < indexNext) {
      if (largeScreen.matches) {
        leftAnimate();
      } else {
        upAnimate();
      }
    } else {
      if (largeScreen.matches) {
        rightAnimate();
      } else {
        downAnimate();
      }
    }
  };

  initAnimate = () => {
    console.log(this.contentRef.current);
    TweenMax.from(this.contentRef.current, 1.2, {
      ease: Power2.easeInOut,
      y: 50,
      opacity: 0,
    });
    TweenMax.from(this.canva1Ref.current, 1.2, {
      ease: Power2.easeInOut,
      x: -100,
      opacity: 0,
    });
    TweenMax.from(this.canva2Ref.current, 1.2, {
      ease: Power2.easeInOut,
      y: 100,
      opacity: 0,
    });

    TweenMax.from(".back-home", 1.2, {
      ease: Expo.easeInOut,
      x: -500,
      opacity: 0,
    });
    TweenMax.fromTo(
      ".overview",
      1.2,
      { ease: Power2.easeInOut, opacity: 0, y: 100 },
      { ease: Power2.easeInOut, opacity: 1, y: 0 }
    );
  };

  componentDidMount() {
    const idArtwork = this.props.match.params.idArtwork;
    if (!this.props.artworks) this.props.getArtworks();
    this.getSelected(idArtwork);
  }
  render() {
    return (
      <>
        <div className={classes.artwork}>
          <div
            className={classNames(classes.splash, classes.splash1)}
            ref={this.splash}
          />
          <div
            className={classNames(classes.splash, classes.splashVertical)}
            ref={this.splashVertical}
          />
          {this.state.selectedArtwork && this.state.loaded && (
            <>
              <div className={classes.bigWord}>
                <h1>{this.state.selectedArtwork.title}</h1>
              </div>
              <div className={classes.artworkContainer}>
                <div className={classes.artworkContent} ref={this.contentRef}>
                  <a className={classes.logo}>R . A</a>
                  <ArrowIcon
                    fill="#4a4a4a"
                    className={classes.backicon}
                    onClick={() => history.push("/")}
                  />
                  <div
                    className={classNames(classes.canva, classes.canva1)}
                    ref={this.canva1Ref}
                  >
                    <BlurImage
                      src={
                        this.state?.selectedArtwork?.images?.[0]?.src
                          ? baseURL +
                            this.state?.selectedArtwork?.images?.[0]?.src
                          : undefined
                      }
                      smallSrc={
                        this.state?.selectedArtwork?.images?.[0]?.pre
                          ? baseURL +
                            this.state?.selectedArtwork?.images?.[0]?.pre
                          : undefined
                      }
                    />
                  </div>
                  <div
                    className={classNames(classes.canva, classes.canva2, {
                      [classes.canvaBot]:
                        !this.state.selectedArtwork?.images?.[1],
                    })}
                    ref={this.canva2Ref}
                  >
                    <BlurImage
                      src={
                        this.state.selectedArtwork?.images?.[1]
                          ? baseURL +
                            this.state.selectedArtwork?.images?.[1]?.src
                          : this.state?.selectedArtwork?.images?.[0]?.src
                          ? baseURL +
                            this.state?.selectedArtwork?.images?.[0]?.src
                          : undefined
                      }
                      smallSrc={
                        this.state.selectedArtwork.images[1]
                          ? baseURL + this.state.selectedArtwork.images[1].pre
                          : this.state?.selectedArtwork?.images?.[0]?.pre
                          ? baseURL +
                            this.state?.selectedArtwork?.images?.[0]?.pre
                          : undefined
                      }
                    />
                  </div>
                  {this.state.selectedArtwork?.link && (
                    <div className={classes.btnView}>
                      <a
                        href={this.state.selectedArtwork?.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        view site
                      </a>
                    </div>
                  )}
                  <div className={classes.title}>
                    <p>{this.state.selectedArtwork.title}</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <NavSlide
          data={this.props.artworks}
          idSelected={this.state.idSelected}
          goTo={this.goTo}
        />
      </>
    );
  }
}

const mapStateToStore = (state) => {
  return {
    artworks: state.project.artworks,
  };
};

const mapDispatchToStore = (dispatch) => {
  return {
    getArtworks: () => dispatch(actions.getArtworks()),
  };
};

export default connect(mapStateToStore, mapDispatchToStore)(Artworks);
