import { httpR } from "./index";
import * as actionTypes from "./actionTypes";

export const getFeatured = () => async (dispatch, state) => {
  let params = {
    method: "GET",
    path: `project/featured`
  };
  const res = await dispatch(httpR(params));
  console.log("getFeatured=>", res);
  if (res && res.success) {
    dispatch({
      type: actionTypes.SET_FEATURED_ARTWORKS,
      data: res.response.payload.rows
    });
    return res;
  } else {
    console.log("error getFeatured");
  }
  return res;
};

export const getArtworks = payload => async (dispatch, state) => {
  let params = {
    method: "GET",
    path: `project`,
    query: payload
  };
  const res = await dispatch(httpR(params));
  console.log("getArtworks=>", res);
  if (res && res.success) {
    dispatch({
      type: actionTypes.SET_ARTWORKS,
      data: res.response.payload.rows
    });
    return res;
  } else {
    console.log("error getArtworks");
  }
  return res;
};
