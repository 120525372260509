import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Artworks from "./pages/Artworks";
import Admin from "./pages/Admin";
import history from "./utility/history";
import Intro from "./components/Intro";
import { connect } from "react-redux";
import * as actions from "./store/actions";

function App({ introState, changeIntroState }) {
  return (
    <Router history={history}>
      <div className="App">
        {introState ? (
          <Intro changeIntroState={changeIntroState} />
        ) : (
          <div
          //style={{ display: `${introState ? "none" : "block"}` }}
          //className={classNames({ [classes.hide]: introState })}
          >
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/artworks/:idArtwork" component={Artworks} />
              <Route path="/admin" component={Admin} />
            </Switch>
          </div>
        )}
      </div>
    </Router>
  );
}

const mapStateToStore = (state) => {
  return {
    introState: state.base.introState,
  };
};

const mapDispatchToStore = (dispatch) => {
  return {
    changeIntroState: (payload) => dispatch(actions.changeIntroState(payload)),
  };
};

export default connect(mapStateToStore, mapDispatchToStore)(App);
