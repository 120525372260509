import React from "react";
import classes from "./Nav.module.scss";
import classNames from "classnames";
const Nav = ({ data, goTo, idSelected }) => {
  return (
    <div className={classes.nav}>
      <ul>
        {data.map((artwork) => {
          return (
            <li
              key={artwork.id}
              className={classNames({
                [classes.active]: idSelected === artwork.id,
              })}
            >
              <a onClick={() => goTo(artwork.id)}>
                <span />
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default Nav;
