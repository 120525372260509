import React, { Component } from "react";
import classes from "./Home.module.scss";
import MyInfo from "components/Home/MyInfo";
import LatestWork from "components/Home/LatestWork";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { TimelineMax, Power2 } from "gsap";

class Home extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.contentRef = React.createRef();
    this.rightContentRef = React.createRef();
  }
  getFeatured = async () => {
    await this.props.getFeatured();
  };
  getArtworks = async () => {
    await this.props.getArtworks();
  };
  animateElement = () => {
    let tl = new TimelineMax();
    let name = this.contentRef.current.firstElementChild;
    let title = this.contentRef.current.lastElementChild;
    let titleRight = this.rightContentRef.current.firstElementChild;
    let moreRight = this.rightContentRef.current.lastElementChild;
    tl.fromTo(
      this.containerRef,
      1,
      {
        opacity: 0,
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        ease: Power2.easeInOut,
      }
    );

    tl.fromTo(
      name,
      0.8,
      {
        ease: Power2.easeInOut,
        opacity: 0,
        x: -100,
      },
      {
        ease: Power2.easeInOut,
        opacity: 1,
        x: 0,
        delay: -0.8,
      }
    );

    tl.fromTo(
      title,
      0.8,
      {
        ease: Power2.easeInOut,
        opacity: 0,
        x: -100,
      },
      {
        ease: Power2.easeInOut,
        opacity: 1,
        x: 0,
        delay: -0.6,
      }
    );

    tl.fromTo(
      titleRight,
      1,
      {
        ease: Power2.easeInOut,
        opacity: 0,
        y: -50,
      },
      {
        ease: Power2.easeInOut,
        opacity: 1,
        y: 0,
        delay: -0.4,
      }
    );

    tl.fromTo(
      moreRight,
      1,
      {
        ease: Power2.easeInOut,
        opacity: 0,
        x: -100,
      },
      {
        ease: Power2.easeInOut,
        opacity: 1,
        x: 0,
        delay: -0.8,
      }
    );
  };
  componentDidMount() {
    this.animateElement();
    if (!this.props.artworks) this.getArtworks();
    if (!this.props.featuredArtworks) this.getFeatured();
  }
  render() {
    const { artworks, featuredArtworks } = this.props;
    return (
      <div className={classes.home} ref={(el) => (this.containerRef = el)}>
        <div className={classes.bigWord}>
          <h1>Rifqi Anshari</h1>
        </div>
        <MyInfo ref={this.contentRef} />
        <LatestWork
          featured={featuredArtworks}
          artworks={artworks}
          ref={this.rightContentRef}
        />
      </div>
    );
  }
}

const mapStateToStore = (state) => {
  return {
    artworks: state.project.artworks,
    featuredArtworks: state.project.featuredArtworks,
  };
};

const mapDispatchToStore = (dispatch) => {
  return {
    getFeatured: () => dispatch(actions.getFeatured()),
    getArtworks: () => dispatch(actions.getArtworks()),
  };
};

export default connect(mapStateToStore, mapDispatchToStore)(Home);
