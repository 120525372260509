import React, { forwardRef } from "react";
import classes from "./LatestWork.module.scss";
import BlurImage from "elements/UI/BlurImage";
import history from "utility/history";
import { iconArrow } from "commons/images";
import { baseURL } from "utility/http";

const LatestWork = forwardRef(({ artworks, featured }, ref) => {
  const goTo = (idArtwork) => {
    history.push(`/artworks/${idArtwork}`);
  };
  return (
    <div className={classes.latestWork} ref={ref}>
      <h5>Selected Projects</h5>
      <div className={classes.latestContent}>
        <div className={classes.latestItem + " " + classes.smallLatest}>
          {featured?.length > 0 &&
            featured.slice(0, 2).map((item, index) => (
              <div
                key={index}
                className={classes.smallItem}
                onClick={() => goTo(item?.id)}
              >
                <div className={classes.artwork}>
                  <BlurImage
                    src={
                      item?.images?.[0]?.src
                        ? baseURL + item?.images?.[0]?.src
                        : undefined
                    }
                    smallSrc={
                      item?.images?.[0]?.pre
                        ? baseURL + item.images?.[0]?.pre
                        : item.images?.[0]?.src
                        ? baseURL + item.images?.[0]?.src
                        : undefined
                    }
                  />
                </div>
              </div>
            ))}
        </div>
        <div className={classes.latestItem + " " + classes.smallLatest}>
          {featured?.length > 0 &&
            featured.slice(2).map((item, index) => (
              <div
                key={index}
                className={classes.smallItem}
                onClick={() => goTo(item?.id)}
              >
                <div className={classes.artwork}>
                  <BlurImage
                    src={
                      item?.images?.[0]?.src
                        ? baseURL + item?.images?.[0]?.src
                        : undefined
                    }
                    smallSrc={
                      item?.images?.[0]?.pre
                        ? baseURL + item.images?.[0]?.pre
                        : item.images?.[0]?.src
                        ? baseURL + item.images?.[0]?.src
                        : undefined
                    }
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className={classes.viewMorePlc}>
        <div
          className={classes.viewMore}
          onClick={() => goTo(artworks?.[0]?.id)}
        >
          view more
          <div className={classes.arrow}>
            <img src={iconArrow} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
});

export default LatestWork;
