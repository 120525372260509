import * as actionTypes from "../actions/actionTypes";

const initialState = {
  introState: true,
  artworks: null,
  featuredArtworks: null
};

const setArtworks = (state, action) => {
  return {
    ...state,
    artworks: action.data
  };
};
const setFeatured = (state, action) => {
  return {
    ...state,
    featuredArtworks: action.data
  };
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FEATURED_ARTWORKS:
      return setFeatured(state, action);
    case actionTypes.SET_ARTWORKS:
      return setArtworks(state, action);
    default:
      return state;
  }
};

export default projectReducer;
